@import 'card';

nav {
  --footer-padding: 5px;
  width:100%;
  background: white;
}

nb-layout-column:has(div.remove-layout-padding)  {
  padding: 0 !important;
}

.avatar-bg {
  width: 50px;
  height: 50px;
  background-size: cover !important;
  background-repeat: none !important;
  background-position: center;
  border-radius: 50%;
}

.flag{
  img{
    width:32px;
    height:22px;
  }
}

.input-form {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.ql-container.ql-snow {
  height: 200px !important;
}

.tippy-tooltip.custom-theme {
  background-color: white;
  color: black;
  font-family: Raleway, sans-serif;
  font-size: 18px;
}

.modal-full {
  position:relative;
  margin: auto;
  min-width:50vw;
  max-width:90vw;
  max-height: 90vh;
  /*width: 100% !important;
  height: 100% !important;

  .cdk-visually-hidden {
    width: 99% !important;
    height: 99% !important;
  }

  nb-dialog-container {
    width: 100% !important;
    height: 100% !important;
  }*/
}

.modal-full-expand {
  width:90vw;
}
