@mixin ngx-layout() {
  @include media-breakpoint-down(is) {
    .row {
      margin-left: -10px;
      margin-right: -10px;
    }
  }
}

/*button, a {
  margin: 0;
  overflow: hidden;
  transform-origin: center;
  //transform: rotate(180deg);
  writing-mode: horizontal-tb;
}*/
