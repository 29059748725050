nb-card{
  nb-card-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.image-header{
      padding: 0;
      img{
        max-width: 100%;
        height: auto;
      }
    }
  }
}
